<template>
  <div>
    <div>
      <b-button size="sm"
                variant="outline-primary"
                @click="showGraphReportChart"
      >
        <span v-if="showGraphReport">
          <feather-icon icon="MinusIcon" />  {{ $t('report.hide-chart') }}
        </span>
        <span v-else>
          <feather-icon icon="PlusIcon" />  {{ $t('student-report-module.show-graph') }}
        </span>
      </b-button>
    </div>
    <b-overlay v-if="showGraphReport"
               :show="isProcessing"
               height="500"
               class="mt-2"
    >
      <div v-if="isDataLoaded">
        <h3>{{ $t('labels.proficiency') }}</h3>
        <div
          class="row"
        >
          <div class="col-4">
            <LessonGroupChart :title="$t('student-report-module.report-pages.listening')"
                              :series="getProblemProficiency('listening')"
                              :labels="[$t('student-report-module.report-pages.right'),$t('student-report-module.report-pages.wrong')]"
            />
          </div>
          <div class="col-4">
            <LessonGroupChart :title="$t('student-report-module.report-pages.speaking')"
                              :series="getProblemProficiency('speaking')"
                              :labels="[$t('student-report-module.report-pages.right'),$t('student-report-module.report-pages.wrong')]"
            />
          </div>
          <div class="col-4">
            <LessonGroupChart :title="$t('student-report-module.report-pages.language-knowledge')"
                              :series="getProblemProficiencyForProblemKnowledge('reading', 'grammar')"
                              :labels="[$t('student-report-module.report-pages.right'),$t('student-report-module.report-pages.wrong')]"
            />
          </div>
        </div>
        <hr>
        <h3>{{ $t('labels.completion') }}</h3>
        <div
          class="row"
        >
          <div class="col-4">
            <LessonGroupChart :title="$t('student-report-module.report-pages.listening')"
                              :series="getTotalCompleteIncompleteProblemBy('listening')"
                              :labels="[$t('student-report-module.report-pages.completed'),$t('student-report-module.report-pages.in-completed')]"
            />
          </div>
          <div class="col-4">
            <LessonGroupChart :title="$t('student-report-module.report-pages.speaking')"
                              :series="getTotalCompleteIncompleteProblemBy('speaking')"
                              :labels="[$t('student-report-module.report-pages.completed'),$t('student-report-module.report-pages.in-completed')]"
            />
          </div>
          <div class="col-4">
            <LessonGroupChart :title="$t('student-report-module.report-pages.language-knowledge')"
                              :series="getTotalCompleteIncompleteProblemKnowledge('reading', 'grammar')"
                              :labels="[$t('student-report-module.report-pages.completed'),$t('student-report-module.report-pages.in-completed')]"
            />
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { BButton, BOverlay } from 'bootstrap-vue'
import LessonGroupChart from './LessonGroupChart.vue'

export default {
  components: {
    LessonGroupChart,
    BButton,
    BOverlay,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      classId: this.$route.query.classroomId,
      studentId: this.$route.params.studentId,
      allData: [],
      allProblems: [],
      showGraphReport: false,
      isProcessing: false,
      isDataLoaded: false,
    }
  },
  created() {
    this.showGraphReportChart()
  },
  methods: {
    showGraphReportChart() {
      if (this.showGraphReport) {
        this.showGraphReport = false
        return
      }
      if (!this.isDataLoaded) {
        this.getCourseGraphReport()
      }
      this.showGraphReport = true
    },
    formatGraphReport() {
      const reportByLessonGroup = this.groupByKey(this.allData, 'lesson_group_id')
      Object.keys(reportByLessonGroup).forEach(key => {
        const data = reportByLessonGroup[key]
        console.log(data)
      })
    },
    getProblemProficiency(skill) {
      const right = this.allData.filter(i => i.skill_type === skill).filter(i => +i.wrong === 0).length
      const wrong = this.allData.filter(i => i.skill_type === skill).filter(i => i.wrong > 0).length
      return [
        right,
        wrong,
      ]
    },
    getProblemProficiencyForProblemKnowledge(skill, tagName) {
      const right = this.allData.filter(i => i.skill_type === skill && !!i.tags?.find(ix => ix.id === tagName)).filter(i => +i.wrong === 0).length
      const wrong = this.allData.filter(i => i.skill_type === skill && !!i.tags?.find(ix => ix.id === tagName)).filter(i => i.wrong > 0).length
      return [
        right,
        wrong,
      ]
    },
    getTotalCompleteIncompleteProblemBy(skill) {
      const completeProblem = this.allData.filter(i => i.skill_type === skill).map(i => i.problem_id)
      const inCompleteProblem = this.allProblems.filter(i => i.skill_type === skill && !completeProblem.includes(i.id))
      return [
        completeProblem.length,
        inCompleteProblem.length,
      ]
    },
    getTotalCompleteIncompleteProblemKnowledge(skill, tagName) {
      const completeProblem = this.allData.filter(i => i.skill_type === skill && !!i.tags?.find(ix => ix.id === tagName)).map(i => i.problem_id)
      const inCompleteProblem = this.allProblems.filter(i => i.skill_type === skill && !completeProblem.includes(i.id) && !!i.tags?.find(ix => ix.id === tagName))
      return [
        completeProblem.length,
        inCompleteProblem.length,
      ]
    },
    getCourseGraphReport() {
      this.isProcessing = true
      this.isDataLoaded = false
      useJwt.getIndividualUserCourseGraphReport(this.studentId, {
        params: {
          lesson_group_id: this.groupId,
          class_id: this.classId,
        },
      }).then(response => {
        this.isDataLoaded = true
        const { result, problems } = response.data.data
        this.allData = result.map(item => ({
          ...item,
          tags: this.JSON_PARSE(item.tags),
        }))
        this.allProblems = problems.map(item => ({
          ...item,
          tags: this.JSON_PARSE(item.tags),
        }))
        this.formatGraphReport()
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
