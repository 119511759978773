<template>
  <div>
    <div class="d-flex justify-content-end">
      <div
        v-for="stat, index of stats"
        :key="index"
        class="d-flex align-items-center mr-4 mb-2"
      >
        <div
          class="stat-indicator"
          :class="getBgVariant(stat.type, stat.symbol)"
        />
        <h5 class="ml-1 mb-0">
          {{ stat.text }}
        </h5>
      </div>
    </div>
    <hr>
    <b-card
      id="summary-per-lesson"
      body-class="p-0 mb-0"
    >
      <div>
        <div
          v-for="summaryType, index of Object.values(groupedSummaryGraph)"
          :key="index"
          class="row"
        >
          <div
            v-for="(summary, sIndex) of summaryType"
            :key="`${summary.lesson_id}_${sIndex}`"
            class="mb-2 progressbar rounded col-md-5 col-sm-12 md-mr-1"
            :class="[getStatusVariant(summary.status)]"
          >
            <h6>
              <b-badge
                pill
                :class="getBadgeVariant(summary.type)"
              >
                {{ snakeToTitleCase(summary.type) }}
              </b-badge>
              {{ summary.lesson.name }} | {{ summary.total_items }} items
              <template v-if="showHideSummaryStatus">
                |
                {{ $t('actions.status') }}:
                {{ $t(`course-completion-module.${summary.status}`) }}
              </template>
            </h6>
            <div class="d-flex">
              <div
                v-for="itemCount in summary.total_items"
                :key="itemCount"
                v-b-tooltip.hover="`${summary.test_stats[itemCount - 1]?.problem.name} (${summary.test_stats[itemCount - 1]?.problem.skill_type})`"
                class="stat-box border"
                :class="getBgVariant(summary.type, summary.test_stats[itemCount - 1]?.symbol ?? null)"
                style="cursor: pointer;"
                @click="getDataForPreview(summary.test_stats[itemCount - 1]?.problem.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <question-preview
      :show="showPreview"
      :data="problemBeingPreviewed"
      :show-edit-mode="false"
      @close="showPreview=false"
    />
  </div>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import i18n from '@/libs/i18n'
import {
  isVisible,
} from '@/utils/visibilitySettings'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_STUDENT } from '@/const/userType'

export default {
  components: {
    BCard,
    BBadge,
    QuestionPreview,
  },
  props: {
    summaryGraph: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stats: [
        {
          type: 'homework',
          symbol: 'P',
          text: i18n.tc('adaptive-learning-module.pass'),
        },
        {
          type: 'homework',
          symbol: 'F',
          text: i18n.tc('adaptive-learning-module.fail'),
        },
        {
          type: 'followup',
          symbol: 'P',
          text: i18n.tc('adaptive-learning-module.pass-followup'),
        },
        {
          type: 'unattempted',
          symbol: null,
          text: i18n.tc('adaptive-learning-module.unattempted'),
        },
      ],
      userId: this.$route.params.studentId,
      classroomId: this.$route.query.classroomId,
      showPreview: false,
      problemBeingPreviewed: {},
    }
  },
  computed: {
    groupedSummaryGraph() {
      return this.groupByKey(this.summaryGraph, 'lesson_id')
    },
    isAStudent() {
      return getUserData() && getUserData().usertype === USER_TYPE_STUDENT
    },
    showHideSummaryStatus() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      const summaryStatus = isVisible('summary_status', schoolSetting, distSetting)
      // console.log(summaryStatus)
      if (this.isAStudent && !summaryStatus) {
        return false
      }
      return true
    },
  },
  methods: {
    getBadgeVariant(type) {
      if (type === 'homework') {
        return 'bg-success'
      }
      return 'bg-followup-pass'
    },
    getBgVariant(type, symbol) {
      if (type === 'homework') {
        switch (symbol) {
          case 'P':
            return 'bg-success'
          case 'F':
            return 'bg-danger'
          default:
            return 'bg-secondary'
        }
      } else {
        switch (symbol) {
          case 'P':
            return 'bg-followup-pass'
          case 'F':
            return 'bg-danger'
          default:
            return 'bg-secondary'
        }
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 'passed':
          return 'progress-success'
        case 'failed':
          return 'progress-danger'
        case 'in_progress':
          return 'progress-warning'
        default:
          return 'bg-secondary'
      }
    },

    async getTemplates(id) {
      if (!id) return
      const data = await useJwt.getTemplate(id)
      // eslint-disable-next-line consistent-return
      return data.data.data
    },
    async getDataForPreview(problemId) {
      const studentTestStat = (await useJwt.getTestStatBasedOnClassRoom(this.classroomId, {
        params: {
          user_id: this.userId,
          problem_id: problemId,
        },
      })).data?.data
      useJwt.getProblem(problemId).then(async res => {
        const problemData = res.data.data
        this.$store.commit('problem/UPDATE_ACTIVE_PROBLEM', problemData)
        let index = studentTestStat ? studentTestStat.lang_level - 1 : 0
        const allLangLevels = [...new Set(problemData.questions.map(i => i.lang_level))]
        if (allLangLevels.length === 1) {
          index = allLangLevels[0] - 1
        }

        const problemStatementLength = problemData.problem_statements.length
        const statementAndFeedback = problemData.problem_statements[index] ? problemData.problem_statements[index] : problemData.problem_statements[problemStatementLength - 1]
        const feedBackTemplate = await this.getTemplates(statementAndFeedback.fb_template_id)
        const statementTemplate = await this.getTemplates(statementAndFeedback.st_template_id)
        const problemPreview = {
          theme: problemData.theme ? problemData.theme : 0,
          problemType: problemData.problem_type.name,
          feedback: {
            type: statementAndFeedback.fb_type,
            text: statementAndFeedback.feedback,
            image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
            audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
            template: feedBackTemplate,
          },
          statement: {
            type: statementAndFeedback.st_type,
            text: statementAndFeedback.statement,
            image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
            audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
            template: statementTemplate,
            video: problemData.video_time_stamp,
          },
          hideLayoutManager: true,
          hideStatement: !!problemData.rules?.hide_statement,
          animatedTemplateId: problemData.animated_template_id,
          hasAnimatedLayout: !!problemData.animated_layout,
          // eslint-disable-next-line eqeqeq
          questions: problemData.questions.filter(e => e.lang_level == (index + 1).toString()),
          answers: studentTestStat?.user_input || [],
          level: index + 1,
          rules: problemData.rules,
        }
        this.problemBeingPreviewed = problemPreview
        this.showPreview = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#summary-per-lesson {
  width: 1180px;
}
.stat-box {
  width: 30px;
  height: 10px;
}

.stat-indicator {
  width: 20px;
  height: 20px;
}

.bg-followup-pass {
  background-color: #007aff;
}
.progressbar {
  display: inline-block;
  padding: 15px;
}
.progress-success {
  background: #8ff7bd !important
}
.progress-danger {
  background: #f3b2b3 !important
}
.progress-warning {
  background: #f7bf8c !important
}

.md-mr-1 {
  margin-right: 1rem
}
@media screen and (max-width: 576px) {
  .md-mr-1 {
    margin-right: 0px
  }
}
</style>
