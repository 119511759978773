<template>
  <b-row class="pt-2 mx-0"
         style="background: #040828 !important"
  >
    <b-col
      v-for="(summary, index) of getSummary"
      :key="index"
      class="d-flex align-items-stretch col"
      md="4"
    >
      <b-card
        class="w-100"
        :class="summary.colorClass"
      >
        <h4 class="text-white"> <feather-icon
          :icon="summary.icon"
          size="20"
        /> {{ summary.title }}</h4>
        <div v-if="summary.value === 'comparative_summary' ">
          <table class="w-100 table-sm">
            <tbody>
              <tr>
                <th>{{ $t('individual-report-module.item') }}</th>
                <th>{{ $t('individual-report-module.my_score') }} (%)</th>
                <th>{{ $t('individual-report-module.max_score') }} (%)
                  <feather-icon v-b-tooltip.hover="`${$t('individual-report-module.max_score_info')}`"
                                class="cursor-pointer"
                                size="20"
                                icon="InfoIcon"
                  />
                </th>
              </tr>
              <tr>
                <td>{{ $t('individual-report-module.reading') }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.reading?.myScore }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.reading?.maxScore }}</td>
              </tr>
              <tr>
                <td>{{ $t('individual-report-module.listening') }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.listening?.myScore }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.listening?.maxScore }}</td>
              </tr>
              <tr>
                <td>{{ $t('individual-report-module.writing') }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.writing?.myScore }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.writing?.maxScore }}</td>
              </tr>
              <tr>
                <td>{{ $t('individual-report-module.speaking') }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.speaking?.myScore }}</td>
                <td>{{ comparativeReport?.CEFR_Level?.speaking?.maxScore }}</td>
              </tr>
              <tr>
                <td>{{ $t('student-diary-module.attendance') }}</td>
                <td>{{ comparativeReport?.attendance?.myScore || 0 }}</td>
                <td>{{ comparativeReport?.attendance?.maxScore || 0 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <table class="w-100 table-sm">
            <tbody>
              <tr>
                <th>{{ $t('individual-report-module.item') }}</th>
                <th>{{ $t('labels.score') }}</th>
              </tr>
              <tr v-for="detail of summary.data"
                  :key="detail.title"
              >
                <td>{{ detail.title }}</td>
                <td>
                  <div v-if="summary.value === 'study-habit' && !detail.preventDivide">
                    <b>{{ number_format(detail.value , 2) }} %</b>
                  </div>
                  <div v-else>
                    <b>{{ detail.value }}</b>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow, BCol, BCard,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    getLessonCompetition: {
      type: Function,
      required: true,
    },
    getAverage: {
      type: Function,
      required: true,
    },
    comparativeReport: {
      type: Object,
      default: () => {},
    },
    expectedReport: {
      type: Object,
      default: () => null,
    },
    actualReport: {
      type: Object,
      default: () => null,
    },
    performanceReport: {
      type: Object,
      default: () => null,
    },
    studyHabit: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    getOverallCourseReport() {
      return this.number_format((this.actualReport.total_course / this.expectedReport?.total_course) * 100, 2)
    },
    getOverallLessonReport() {
      return this.number_format((this.actualReport.total_lesson / this.expectedReport?.total_lessons) * 100, 2)
    },
    getOverallPassScore() {
      let total = 0
      this.tableData.forEach(item => {
        total += this.getAverage(item, 'pass_score')
      })
      return this.number_format(total / this.tableData.length, 2)
    },
    getOverallCefrRate() {
      let total = 0
      this.tableData.forEach(item => {
        total += this.getAverage(item, 'average')
      })
      return this.number_format(total / this.tableData.length, 2)
    },
    getTotalStudentHabit() {
      return this.getSummary[0]?.data?.filter(item => !item.preventDivide).reduce((p, c) => p + Number(c.value), 0)
    },
    getLessonDone() {
      return this.tableData.reduce((p, c) => p + Number(c.lessons.length), 0)
    },
    getTotalTime() {
      const times = []
      this.tableData.forEach(item => {
        item.lessons.forEach(k => {
          if (k.total_time) {
            times.push(k.total_time)
          }
        })
      })
      return this.addMultipleTimesAndGetTotalTime(times)
    },
    getSummary() {
      return [
        {
          title: i18n.t('individual-report-module.study-habit.title'),
          colorClass: 'bg-light-primary',
          icon: 'BookOpenIcon',
          value: 'study-habit',
          data: [
            {
              title: i18n.t('individual-report-module.study-habit.total_late'),
              value: this.studyHabit?.late || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_not_done'),
              value: this.studyHabit?.not_done || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_early'),
              value: this.studyHabit?.early || 0,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_on_time'),
              value: this.studyHabit?.on_time || 0,
            },
            // {
            //   title: i18n.t('individual-report-module.study-habit.total_time_on_homework'),
            //   value: this.getTotalTime,
            //   preventDivide: true,
            // },
            {
              title: i18n.t('individual-report-module.study-habit.total_course_done'),
              value: `${this.actualReport.total_course}/${this.expectedReport?.total_course}`,
              preventDivide: true,
            },
            {
              title: i18n.t('individual-report-module.study-habit.total_lesson_done'),
              value: `${this.actualReport.total_lesson}/${this.expectedReport?.total_lessons}`,
              preventDivide: true,
            },
          ],
        },
        {
          title: i18n.t('individual-report-module.comparative_summary.title'),
          colorClass: 'bg-light-warning',
          icon: 'BookOpenIcon',
          value: 'comparative_summary',
          data: [],
        },
        {
          title: i18n.t('individual-report-module.performance_summary.title'),
          colorClass: 'bg-light-success',
          icon: 'AwardIcon',
          value: 'performance_summary',
          data: [
            {
              title: i18n.t('individual-report-module.performance_summary.avg_course_competition'),
              value: `${this.getOverallCourseReport}%`,
            },
            {
              title: i18n.t('individual-report-module.performance_summary.avg_lessons_competition'),
              value: `${this.performanceReport?.avg_lessons_competition_rate}%`,
            },
            {
              title: i18n.t('individual-report-module.performance_summary.avg_pass_score'),
              value: `${this.performanceReport?.avg_pass_score}%`,
            },
            {
              title: i18n.t('individual-report-module.performance_summary.avg_cefr_rate'),
              value: `${this.performanceReport?.avg_cefr_rate}%`,
            },
            {
              title: i18n.t('individual-report-module.performance_summary.avg_lang_level'),
              value: this.performanceReport?.avg_cefr_level,
            },
          ],
        },
      ]
    },
  },
}
</script>
<style lang="scss">
 .bg-light-primary {
  table {
    td {
      color: #7367f0 !important
    }
  }
 }
 .bg-light-warning {
  table {
    td {
      color: #ff9f43 !important
    }
  }
 }
 .bg-light-success {
  table {
    td {
      color: #28c76f !important
    }
  }
 }
</style>
